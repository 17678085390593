@import url("https://use.fontawesome.com/releases/v5.0.1/css/all.css");

button:focus {
  outline: none;
}

body {
  margin: 0px;
  font-family: "Lato" !important;
}

a {
  text-decoration: none;
}

p {
  line-height: 1.4rem;
}
:root {
  --color-primary-dark: #2a3140;
  --color-danger: #cc4752;
  --color-primary: #5b89c5;
  --color-background: #eff2f9;
  --color-success: #68b219;
  --color-light-text: #eff2f9;
  --color-dark-text: #020202;
  --color-secondary: #627395;
  --color-faded-text: #bfc6d6;
  --color-focued-input: rgb(120, 202, 255);
  --padding-xs: 0.6rem;
  --padding-sm: 1rem;
  --padding-standard: 2rem;
  --padding-lg: 3rem;
  --height-header: 70px;
}
